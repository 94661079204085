import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplateRef } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/product.service';
import { HttpClient } from '@angular/common/http';
import { APIService } from 'src/app/shared/service/apiService.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  profileImageUrl: string = '';
  public formSubmitted: boolean = false;
  public active = 1;
  public ultimoGradoEstudios: string = '';
  public carrera: string = '';
  public escuela: string = '';
  public aboutMe: string = '';
  public fbUrl: string = '';
  public xUrl: string = '';
  public lnUrl: string = '';
  public nuevoAntecedente = { empresa: '', cargo: '', periodoInicio: '', periodoFin: '' };
  public antecedentes: { empresa: string, cargo: string, periodoInicio: string, periodoFin: string }[] = [];
  profile: any;
  Id: any;
  onEdit = false;

  constructor(
    public dialog: MatDialog,
    private apiService: APIService,
    private productService: ProductsService // Inyectar servicio
  ) { }

  ngOnInit() {
    // Cargar la imagen del localStorage al iniciar el componente
    //const savedImage = localStorage.getItem('profileImage');
    const user = JSON.parse(localStorage['currentUser']);
    this.profileImageUrl = user.imgProfile;

    this.apiService.getById(user.id).subscribe((data: any) => {
      this.profile = data;
      this.Id = this.profile.id;
      console.log('Info',this.Id);
    });

  }

  // Disparar el input de selección de archivo
  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  editProfile(data) {
    // Envía los datos usando el servicio
    this.productService.updateProfile(this.Id, data).subscribe(
        response => {
            console.log('Datos enviados correctamente:', response);
            this.formSubmitted = true; // Cambia el estado cuando el formulario se envía correctamente
        },
        error => {
            console.error('Error al enviar los datos:', error);
            this.formSubmitted = false; // Mantener false en caso de error
        }
    );
  }

  saveEducation() {
    const body = {
      grado: this.profile.grado,
      carrera: this.profile.carrera,
      escuela: this.profile.escuela
    }

    this.editProfile(body);

  }

  saveProfile() {
    if (!this.onEdit) {
      this.onEdit = true;
      return;
    }

    const body = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      displayName: `${this.profile.firstName} ${this.profile.lastName}`,
      email: this.profile.email,
      phoneNumber: this.profile.phoneNumber,
      biography: this.profile.biography
    }

    this.editProfile(body);
    this.onEdit = false;

  }

  // Función que se activa cuando se selecciona un archivo
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      // Esta función se ejecuta cuando el archivo ha sido leído
      reader.onload = (e: any) => {
        // Actualiza la URL de la imagen de perfil con la nueva imagen
        this.profileImageUrl = e.target.result;

        // Guardar la imagen en localStorage
        localStorage.setItem('profileImage', e.target.result);
      };

      // Leer el archivo seleccionado como Data URL (base64)
      reader.readAsDataURL(file);
    }
  }



  mostrarFormulario(templateRef: TemplateRef<any>): void {
    const dialogRef = this.dialog.open(templateRef, {
      width: '500px',
      position: { top: '100px' },
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  checkCharacterLimit() {
    if (this.aboutMe.length >= 300) {
      this.aboutMe = this.aboutMe.substring(0, 300);
    }
  }

  guardarAntecedente() {
    if (this.nuevoAntecedente.empresa && this.nuevoAntecedente.cargo && this.nuevoAntecedente.periodoInicio) {
      this.antecedentes.push({ ...this.nuevoAntecedente });
      console.log(this.antecedentes);
      this.nuevoAntecedente = { empresa: '', cargo: '', periodoInicio: '', periodoFin: '' };
      this.dialog.closeAll(); // si quiero que dar clic en cerrar, lo pongo como comentario
    }
  }

  enviarDatosPerfil() {
    const data = {
      ultimoGradoEstudios: this.ultimoGradoEstudios,
      carrera: this.carrera,
      escuela: this.escuela,
      aboutMe: this.aboutMe,
      antecedentes: this.antecedentes,
      fbUrl: this.fbUrl,
      xUrl: this.xUrl,
      lnUrl: this.lnUrl,
      periodoInicio: this.nuevoAntecedente.periodoInicio,
      periodoFin: this.nuevoAntecedente.periodoFin
    };

    this.editProfile(data);
  }
}


