<div class="row">
  <div class="col-md-4">
    <div *ngIf="!crearPlantilla"><!--ngIf*="!crearPlantilla"-->
      <form [formGroup]="infoForm" (ngSubmit)="onSubmit()">

        <div class="">
          <label class="form-label">Selecciona tus colores</label>
          <div class="d-flex gap-3 align-items-center">
            <div style="flex: 1;">
              <label for="color1" class="form-label text-secondary">Color Primario</label>
              <input type="color" formControlName="color1" (change)="guardarColores()"
                class="form-control form-control-color p-1 border-0 rounded shadow-sm"
                style="width: 100%; height: 40px;" required>
            </div>
            <div style="flex: 1;">
              <label for="color2" class="form-label text-secondary">Color Secundario</label>
              <input type="color" formControlName="color2" (change)="guardarColores()"
                class="form-control form-control-color p-1 border-0 rounded shadow-sm"
                style="width: 100%; height: 40px;" required>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="logo" class="form-label">Logo o Marca</label>
          <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 180px de alto.</p>
          <input style="height: 38px !important;" type="file" formControlName="logo"
            (change)="onFileSelected($event, 'logo')" class="form-control placeholder-text" required>
          <div *ngIf="logo" class="mt-3">
            <img [src]="logo" alt="Logo o Marca" class="img-fluid" style="width: 100px;" />
            <button type="button" class="edu-btnTransition" (click)="removeImage('logo')">Eliminar Imagen</button>
          </div>
        </div>

        <mat-form-field appearance="outline" class="mb-3 w-100">
          <mat-label>Tipografía</mat-label>
          <mat-select formControlName="tipografia" (selectionChange)="guardarTipografia()" required>
            <mat-option value="" disabled selected>Seleccione una tipografía</mat-option>
            <mat-option *ngFor="let font of fuentes" [value]="font">{{ font }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="isFieldInvalid('tipografia')" class="text-danger">
            Este campo es requerido.
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Titulo Gancho</mat-label>
          <input matInput type="text" required class="w-100" formControlName="gancho" [(ngModel)]="gancho"
            placeholder="Titulo Gancho" [ngClass]="{'is-invalid': isFieldInvalid('gancho')}" required>
          <mat-error *ngIf="isFieldInvalid('gancho')">Este campo es requerido.</mat-error>
        </mat-form-field>


        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Propuesta de valor</mat-label>
          <input matInput type="text" formControlName="propuesta" [(ngModel)]="propuesta"
            placeholder="Propuesta de valor" [ngClass]="{'is-invalid': isFieldInvalid('propuesta')}" required>
          <mat-error *ngIf="isFieldInvalid('propuesta')">
            Este campo es requerido.
          </mat-error>
        </mat-form-field>



        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Deseo</mat-label>
          <input matInput type="text" formControlName="GatilloM" [(ngModel)]="deseo" placeholder="Deseo"
            [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <mat-error *ngIf="isFieldInvalid('deseo')">
            Este campo es requerido.
          </mat-error>
        </mat-form-field>



        <div class="mb-3">
          <label for="imagen" class="form-label">Imagen del curso</label>
          <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 550px de alto.</p>
          <input style="height: 38px !important;" type="file" formControlName="imagen"
            (change)="onFileSelected($event,'imagenCurso')" class="form-control placeholder-text"
            [ngClass]="{'is-invalid': isFieldInvalid('imagen')}" required>
          <div *ngIf="isFieldInvalid('imagen')" class="text-danger">
            Este campo es requerido.
          </div>
          <div *ngIf="selectedImage" class="mt-3">
            <img [src]="selectedImage" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
            <button type="button" class="edu-btnTransition" (click)="removeImage('imagenCurso')">Eliminar
              Imagen</button>
          </div>
        </div>


        <mat-form-field required class="w-100 mb-3" appearance="outline" style="margin-top: 1rem;">
          <mat-label>¿Qué se obtendra del curso?</mat-label>
          <mat-chip-grid #chipGrid>
            <mat-chip-row *ngFor="let obtendras of queObtendras; trackBy: trackByFn" (removed)="remove(obtendras)"
              [editable]="true" [aria-description]="'press enter to edit ' + obtendras.name">
              {{ obtendras.name }}
              <a matChipRemove [attr.aria-label]="'remove ' + obtendras.name">
                <mat-icon>cancel</mat-icon>
              </a>
            </mat-chip-row>
            <input placeholder="Palabras clave" [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)" formControlName="resAvatarNom" />
          </mat-chip-grid>
        </mat-form-field>

        <div class="mb-3">
          <label for="imgObtendras" class="form-label">Imagen apartado beneficios</label>
          <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 550px de alto.</p>
          <input style="height: 38px !important;" type="file" formControlName="imgObtendras"
            (change)="onFileSelected($event,'imgObtendras')" class="form-control placeholder-text"
            [ngClass]="{'is-invalid': isFieldInvalid('imgObtendras')}" required>
          <div *ngIf="isFieldInvalid('imgObtendras')" class="text-danger">
            Este campo es requerido.
          </div>
          <div *ngIf="imgObtendras" class="mt-3">
            <img [src]="imgObtendras" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
            <button type="button" class="edu-btnTransition" (click)="removeImage('imgObtendras')">Eliminar
              Imagen</button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">¿Quien nos respalda?</label>
          <p style="margin-bottom: 0px;">*Las imágenes deben ser menor o igual a 550px de ancho y 550px de alto.</p>
          <input style="height: 38px !important;" type="file" formControl="quienNosRespalda"
            (change)="onFileSelected($event,'respaldo')" class="form-control placeholder-text" multiple>
          <div *ngIf="imagenesRespaldo" class="mt-3">
            <div *ngFor="let images of imagenesRespaldo; let i = index ">
              <img [src]="images" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
              <button style="height: 46px;" type="button" class="edu-btnTransition"
                (click)="removeImageRespald(i)">Eliminar Imagen</button>
            </div>
          </div>
        </div>

        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>¿Quiénes somos?</mat-label>
          <textarea matInput required class="w-100" [(ngModel)]="somos" formControlName="somos"
            placeholder="¿Quiénes somos?*" [ngClass]="{'is-invalid': isFieldInvalid('somos')}">
          </textarea>
        </mat-form-field>
        <div *ngIf="isFieldInvalid('somos')" class="text-danger">
          Este campo es requerido.
        </div>

        <div class="mb-3">
          <label for="imagenUsuario" class="form-label">Imagen tuya</label>
          <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 650px de alto.</p>
          <input style="height: 38px !important;" type="file" formControlName="imagenUsuario"
            (change)="onFileSelected($event,'imagenUsuario')" class="form-control placeholder-text"
            [ngClass]="{'is-invalid': isFieldInvalid('imagenUsuario')}" required>
          <div *ngIf="isFieldInvalid('imagenUsuario')" class="text-danger">
            Este campo es requerido.
          </div>
          <div *ngIf="imagenUsuario" class="mt-3">
            <img [src]="imagenUsuario" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
            <button type="button" class="edu-btnTransition" (click)="removeImage('imagenUsuario')">Eliminar
              Imagen</button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Recursos de regalo</label>
          <p style="margin-bottom: 0px;">*Las imágenes deben ser menor o igual a 550px de ancho y 550px de alto.</p>
          <input style="height: 38px !important;" type="file" formControlName="imagenesRecRegalo"
            (change)="onFileSelected($event,'recursosRegalo')" class="form-control placeholder-text" multiple>
          <div *ngIf="imagenesRecRegalo" class="mt-3">
            <div *ngFor="let images of imagenesRecRegalo; let i = index ">
              <img [src]="images" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
              <button style="height: 46px;" type="button" class="edu-btnTransition"
                (click)="removeImageRespald2(i)">Eliminar Imagen</button>
            </div>
          </div>
        </div>


        <div [formGroup]="form">
          <div formArrayName="preguntasRespuestas">
            <div *ngFor="let preguntaRespuesta of preguntasRespuestas.controls; let i = index" [formGroupName]="i"
              class="row align-items-center mb-2">
              <div class="col-md-5">
                <input style="margin: 0;" type="text" formControlName="pregunta" class="form-control"
                  placeholder="Escribe tu pregunta">
              </div>
              <div class="col-md-2 text-center">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
              <div class="col-md-5">
                <input style="margin: 0;" type="text" formControlName="respuesta" class="form-control"
                  placeholder="Escribe tu respuesta">
              </div>
              <div class="col-md-12 text-end mt-2">
                <button type="button" class="btn btn-danger" (click)="removePreguntaRespuesta(i)">Eliminar</button>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary mt-2" (click)="addPreguntaRespuesta()">Agregar Pregunta y
            Respuesta</button>
        </div>


        <div class="mb-3">
          <label for="deseo" class="form-label">Sube tu recurso</label><!--Checkar si este no es el del recurso-->

          <input style="margin: 0; height: 38px !important;" type="file" formControlName="deseo"
            (change)="onFileSelected($event,'recurso')" class="form-control placeholder-text"
            [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <div *ngIf="isFieldInvalid('deseo')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>

        <mat-form-field appearance="outline" class="mb-3 w-100">
          <mat-label>Tipo de recurso</mat-label>
          <mat-select formControlName="tipoRecurso" (selectionChange)="onTipoRecursoChange($event)" required>
            <mat-option value="" disabled selected>Seleccione el tipo de recurso</mat-option>
            <mat-option *ngFor="let tipo of tiposDeRecursos" [value]="tipo">{{ tipo }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="isFieldInvalid('tipoRecurso')" class="text-danger">
            Este campo es requerido.
          </mat-hint>
        </mat-form-field>

        <div class="mb-3" *ngIf="tipoRecursoSeleccionado"> <!-- Muestra solo si se ha seleccionado un tipo -->
          <label for="deseo" class="form-label">Subir archivo</label>
          <input style="height: 38px !important;" type="file" (change)="onFile($event)"
            class="form-control placeholder-text" [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <div *ngIf="isFieldInvalid('deseo')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" (click)="generarLanding()">Generar Landing</button>
        </div>

      </form>
    </div>
  </div>
  <div class="col-md-8" *ngIf="!crearPlantilla">
    <h5 class="mb-4 text-center text-dark">Esta es la vista previa de cómo se verá tu landing page.</h5>

    <div class="bg-white">
      <div class="browser-window w-100">
        <div class="browser-header">
          <div class="buttons">
            <span class="button close"></span>
            <span class="button minimize"></span>
            <span class="button maximize"></span>
          </div>
          <div class="address-bar">
            <!-- <input type="text" disabled [value]="course.Slug" readonly> -->
          </div>
        </div>
        <div class="browser-content"><!--class="browser-content"-->
          <app-landing-curso [gancho]="gancho" [micronicho]="micronicho" [propuesta]="propuesta" [deseo]="deseo"
            [GatilloM]="GatilloM" [somos]="somos" [selectedImage]="selectedImage" [imgObtendras]="imgObtendras"
            [imagenUsuario]="imagenUsuario" [imagenesRecRegalo]="imagenesRecRegalo"
            [imagenesRespaldo]="imagenesRespaldo" [FAQs]="FAQs" [respuestas]="respuestas" [color1]="color1"
            [color2]="color2" [logo]="logo" [tipografia]="tipografiaSeleccionada"></app-landing-curso>

          <ul>
            <li *ngFor="let resultado of resultados">
              {{ resultado.name }}
            </li>
          </ul>
          <div *ngIf="imagenUrl">
            <img [src]="imagenUrl" alt="Imagen seleccionada" class="img-fluid" />
          </div>
          <p *ngIf="archivoSeleccionado">Archivo seleccionado: {{ archivoSeleccionado.name }}</p>
          <!-- <p>{{somos}}</p> -->
          <p>{{FAQs}}</p>
        </div>
      </div>
    </div>
  </div>
</div>