import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import * as moment from 'moment';
import { GuidedTourService } from 'ngx-guided-tour';
import { TourStep } from 'ngx-guided-tour';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/shared/service/products.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  private subs: Array<Subscription> = [];
  public videos: any[] = []; // Lista de videos obtenidos del backend
  constructor(private dashboardService: DashboardService, private guidedTourService: GuidedTourService,private router: Router, private productService: ProductsService,) {
    Object.assign(this, { doughnutData, pieData })
  }

  public admin: any;
  public courses: any;
  public graph: any;
  public dashdates: any;
  public members: any;
  public month: string;
  public year: string;
  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;


  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    this.month = moment().locale('es').format('MMMM');
    this.month = this.month.charAt(0).toUpperCase() + this.month.slice(1);
    this.year = moment().format('yyyy');
    this.subs.push(
      this.dashboardService
      .getTotals()
      .subscribe(data => {
        this.admin = data;
      }),
      this.dashboardService
      .getTopCourses()
      .subscribe(data => {
        this.courses = data;
      }),
      this.dashboardService
      .getCoursesGraph()
      .subscribe(data => {
        this.graph = data;
        this.graph = this.graph.map(item => {return {name: item.name, value: item.value.toFixed(2)}})
      }),
      this.dashboardService
      .getDashDates()
      .subscribe(data => {
        this.dashdates = data;
      }),
      this.dashboardService
      .getTopMembers()
      .subscribe(data => {
        const miembros = data;
        if(miembros.length > 5){
          this.members = miembros.slice(0, 5);
        }
        else{
          this.members = miembros;
        }
      }),
    );
    this.loadVideos(); // Cargar los videos dinámicamente
    setTimeout(() => {
      this.startTour(); // Iniciar el tour después de un breve retraso
    }, 1000); // Ajusta el tiempo según la carga de los videos
  }

  loadVideos(): void {
    const currentUser = localStorage.getItem('currentUser');
  
    if (!currentUser) {
      console.error('El usuario actual no está definido en localStorage.');
      alert('No se pudo obtener la información del usuario.');
      return;
    }
  
    const user = JSON.parse(currentUser);
    const userId = user.id;
  
    if (!userId) {
      console.error('El UserId no está disponible.');
      alert('Hubo un problema al identificar al usuario.');
      return;
    }
  
    const descriptions = ['Introduccion', 'Dashboard']; // Las dos descripciones posibles
    this.videos = []; // Inicializa la lista de videos
  
    descriptions.forEach((descripcion) => {
      const requestBody = {
        descripcion: descripcion,
        userId: userId
      };
  
      this.productService.getVideos(requestBody).subscribe({
        next: (response) => {
          if (response && response.length > 0) {
            // Agrega los videos a la lista en orden según la descripción
            this.videos.push(...response.filter(video => video.Description === descripcion));
          }
          console.log('Videos cargados:', this.videos);
        },
        error: (err) => {
          console.error(`Error al cargar los videos para "${descripcion}":`, err);
          alert(`Hubo un problema al cargar los videos para "${descripcion}".`);
        }
      });
    });
  }  

  markVideoAsViewed(videoId: number): void {
    this.productService.changeStatusVideo(videoId).subscribe({
      next: () => {
        console.log(`El video con ID ${videoId} ha sido marcado como visto.`);
        // Opcional: Actualiza el estado local de los videos si es necesario
      },
      error: (err) => {
        console.error(`Error al cambiar el estado del video con ID ${videoId}:`, err);
        alert('Hubo un problema al actualizar el estado del video.');
      }
    });
  }

  markAllVideosAsViewed(): void {
    this.videos.forEach(video => {
      if (!video.OnboardingVideoUsers[0]?.Viewed) {
        this.productService.changeStatusVideo(video.OnboardingVideoUsers[0]?.id).subscribe({
          next: () => {
            console.log(`Video con ID ${video.OnboardingVideoUsers[0]?.id} marcado como visto.`);
          },
          error: (err) => {
            console.error(`Error al marcar video con ID ${video.OnboardingVideoUsers[0]?.id}:`, err);
          }
        });
      }
    });
  }

  startTour() {
    // Verifica si la lista de videos está vacía
    if (this.videos.length === 0) {
      console.log('No hay videos disponibles para iniciar el tour.');
      return; // Detiene el inicio del tour
    }

    // Verifica si algún video ya ha sido visto
    const algunVideoVisto = this.videos.some(video => video.OnboardingVideoUsers[0]?.Viewed);

    if (algunVideoVisto) {
      console.log('El tour no se iniciará porque algunos videos ya han sido vistos.');
      return; // Detiene el inicio del tour
    }

    const tour = {
      tourId: 'generalTour',
      useOrb: false,  // Desactiva el orb
      steps: this.getGeneralSteps(),
      completeCallback: () => {
        console.log('Tour completado');
        this.router.navigate(['/products/courses']);  // Redirige al nuevo componente
      },
      skipCallback: () => {
        console.log('Tour omitido');
        this.markAllVideosAsViewed();
        this.router.navigate(['/products/courses']);  // Redirige al nuevo componente si se omite el tour
      }
    };
    document.querySelector('ngx-guided-tour')?.classList.add('general-tour');
    this.guidedTourService.startTour(tour);  // Inicia el tour
  }

  getGeneralSteps(): TourStep[] {
    // Generar pasos dinámicamente para los videos cargados
    const videoSteps = this.videos.map((video) => {
      const onboardingUserId = video.OnboardingVideoUsers[0]?.id; // Obtener el ID correcto
      return {
        content: `
        <video controls width="100%" height="100%" (ended)="markVideoAsViewed(${onboardingUserId})">
          <source src="${video.VideoUrl}" type="video/mp4">
          Tu navegador no soporta la reproducción de video.
        </video>
        `,
        title: video.Description,
        orientation: 'center',
        scrollAdjustment: 0,
        closeAction: () => {
          console.log(`Cerrando el paso del video con ID ${onboardingUserId}.`);
          this.markVideoAsViewed(onboardingUserId); // Marca el video como visto al cerrar el paso
        }
      };
    });
  
    // Agregar pasos adicionales si es necesario
    const additionalSteps = [
      {
        content: 'En el panel izquierdo, puedes ver las secciones principales.',
        title: 'Menú',
        selector: '#sidebar-menu',
        orientation: 'center',
        scrollAdjustment: 0,
      },
      {
        content: 'Recuerda que puedes acceder a tu perfil desde la parte superior derecha.',
        title: 'Perfil',
        orientation: 'center',
        selector: '#perfil',
        scrollAdjustment: 0,
      },
      {
        content: 'Ahora nos moveremos a los cursos para que puedas empezar a crear tu primer curso en Learnify.',
        title: 'Fin',
        orientation: 'center',
        scrollAdjustment: 0,
      },
    ];
  
    // Combinar los pasos de video con los pasos adicionales
    return [...videoSteps, ...additionalSteps];
  }  
}
