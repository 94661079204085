import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from 'src/app/shared/service/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import * as Notiflix from 'notiflix';
import { HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class ProductDetailComponent implements OnInit {
  getProgressBarType(progress: number): string {
    if (progress < 50) {
      return 'danger'; // Rojo si es menor de 50%
    } else if (progress >= 50 && progress < 80) {
      return 'warning'; // Amarillo entre 50% y 80%
    } else {
      return 'success'; // Verde si es mayor de 80%
    }
  }
  productInfo: any;
  archivoUrl: SafeResourceUrl | null = null;

  constructor(
    private route: Router,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  verArchivoMicrosoft(url: string): void {
    const fullUrl = `http://docs.google.com/gview?url=${url}&embedded=true`;
    console.log("Mostrando archivo en:", fullUrl);
    // Sanitiza la URL antes de asignarla al iframe
    window.open(fullUrl, '_blank');
  } 

  updateVideo(event: any, topic: any, moduloNumero: number, leccionNumero: number) {
    const file = event.target.files[0];
    if (file) {
      topic.uploading = true; // Indicar que la subida ha comenzado
      const formData = new FormData();
      formData.append('name', `Modulo ${moduloNumero}, Lección ${leccionNumero}`);
      formData.append('type', 'videos');
      formData.append('videoupload', file);
  
      this.productService.uploadVideoModulo(formData).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // Calcula el porcentaje del progreso
            const percentDone = Math.round((100 * event.loaded) / (event.total || 1));
            console.log(`Progreso: ${percentDone}%`);
            topic.progress = percentDone; // Actualiza el progreso en el objeto
          } else if (event.type === HttpEventType.Response) {
            // Video subido con éxito
            topic.uploaded = true; // Indicar que el video ha sido subido
            topic.uploading = false; // Finalizar estado de subida
            topic.FileName = file.name; // Guardar el nombre del archivo
            topic.FileSize = this.formatFileSize(file.size); // Guardar el tamaño del archivo
            topic.Type = file.type; // Guardar el tipo del archivo
  
            // Crear el objeto para actualizar el tópico
            const newtopic = {
              videoUrl: event.body.videoUrl,
              FileName: topic.FileName,
              FileSize: topic.FileSize, // Agregar el tamaño del archivo
              Type: topic.Type, // Agregar el tipo del archivo
            };
  
            // Actualizar el tópico con la información del video
            this.productService.updateTopic(topic.id, newtopic).subscribe((data: any) => {
              Notiflix.Notify.success('Video cargado correctamente');
              topic.videoUrl = event.body.videoUrl; // Actualizar la URL del video en el tópico
            });
          }
        },
        (error) => {
          console.error(`Error al subir el video ${file.name} para el módulo ${moduloNumero}, lección ${leccionNumero}:`, error);
          topic.uploading = false; // Manejo de error
        }
      );
    }
  }
   

  ngOnInit(): void {
    this.loadData();
  }
  async loadData() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const query = this.productService.getProduct(id);
    this.productInfo = await lastValueFrom(query);

    const resources = this.productInfo.ProductInfo.filter((pi: any) => pi.Property === 'Resource');
    this.productInfo.product.forEach(element => {
      element.Topics = element.Topics.map((element: any) => {
        return {
          ...element,
          uploading: false,
          vimeoUploading: false,
          Resources: resources.filter((r: any) => r.Commentary === element.id.toString())
        }
      })
    });
  }

  setActive() {
    const body = {
      id: this.productInfo.id,
      Active: true
    }
    this.productService.updateCourse2(this.productInfo.id, body).subscribe((data: any) => {
      Notiflix.Notify.success('Curso pulicado correctamente');
      this.route.navigateByUrl('/products/courses');
    });
  }

  sharedToVimeo(topic: any) {
    topic.vimeoUploading = false;
    const body = {
      name: topic.Title,
      path: topic.videoUrl,
    }

    this.productService.sharedVideo(body).subscribe((data: any) => {
      const newtopic = {
        videoUrl: data.videoUrl,
      }
      this.productService.updateTopic(topic.id, newtopic).subscribe((data: any) => {
        topic.videoUrl = data.videoUrl;
        topic.vimeoUploading = false;
        Notiflix.Notify.success('Video publicado correctamente')
      });
    })

  }

  onSelectImages(event) {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'images');
      this.productService.uploadFile2(formData)
        .subscribe(resolve => {
          if (resolve.FileUrl !== null) {
            const body = {
              id: this.productInfo.id,
              ImgFileUrl: resolve.ImageUrl
            };
            this.productService.updateCourse2(this.productInfo.id, body).subscribe((data: any) => {
              this.productInfo.ImgFileUrl = resolve.ImageUrl;
              Notiflix.Notify.success('Imagen cargada correctamente')
            });
          }
        });
    }
  }

  formatFileSize(size: number): string {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
 
}