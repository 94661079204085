
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs';
import { ProductsService } from 'src/app/shared/service/product.service';
import { productDB } from 'src/app/shared/tables/product-list';
import { GuidedTourService } from 'ngx-guided-tour';
import { TourStep } from 'ngx-guided-tour';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  //public product_list = []
  //this.product_list = productDB.product;

  public url: string = '';
  public search: string = '';
  public product_list: any[] = [];
  public filteredProductList: any[] = []; // Lista filtrada de productos
  public filteredCourses: any[] = [];
  public totalProducts: number;
  public showFilter = false;
  public productId: number;
  public deleteIsSuccess = false;
  public selectedStatus: string = ''; // Estado seleccionado
  active: boolean;
  public video: any[] = [];
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private productService: ProductsService,
    private guidedTourService: GuidedTourService
  ) { }

  filterCoursesByStatus() {
    if (this.selectedStatus) {
        this.filteredCourses = this.product_list.filter(course => {
            if (this.selectedStatus === 'borrador') {
                // Mostrar solo los cursos que no están completos (CoursePct != '100.00')
                return course.CoursePct !== '100.00';
            } else if (this.selectedStatus === 'publicado') {
                // Mostrar solo los cursos activos (publicados) que están completos
                return course.CoursePct === '100.00' && course.Active;
            } else if (this.selectedStatus === 'revision') {
                // Mostrar solo los cursos en revisión (no activos) que están completos
                return course.CoursePct === '100.00' && !course.Active;
            }
        });
    } else {
        // Si no hay filtro, mostrar todos los cursos
        this.filteredCourses = this.product_list;
    }
  }


  ngOnInit() {
    const storedSearch = localStorage.getItem('searchValue');
    if (storedSearch) {
      this.search = storedSearch;
      this.showFilter = true;
    }
    this.loadData();
    this.loadVideos(); // Cargar el video para el tour

    // Inicia el tour después de asegurarse de que el video esté cargado
    setTimeout(() => {
      this.startTour();
    }, 1000);
  }

  updateSearchValue(newValue: string) {
    this.search = newValue;
    if (this.search.trim()) {
      localStorage.setItem('searchValue', this.search);
    }
    this.loadData();
  }

  clearSearch() {
    this.search = '';
    this.selectedStatus = '';  // Limpiar el estado seleccionado
    localStorage.removeItem('searchValue');
    this.loadData();  // Recargar los datos después de limpiar los filtros
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  setModalContent(content: any, productId: number, status: boolean): void {
    this.productId = productId;
    this.active = status;
    this.modalService.open(content, this.modalConfig);
  }

  loadData() {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.productService.getCourses2(this.search, user.id).subscribe(data => {
        this.product_list = data;
        this.filteredCourses = this.product_list; // Inicialmente mostrar todos los cursos
        this.totalProducts = this.product_list.length;
        /* console.log('info de los cursos:', this.product_list); */
    });
  }

  loadVideos(): void {
    const currentUser = localStorage.getItem('currentUser');
  
    if (!currentUser) {
      console.error('El usuario actual no está definido en localStorage.');
      alert('No se pudo obtener la información del usuario.');
      return;
    }
  
    const user = JSON.parse(currentUser);
    const userId = user.id;
  
    if (!userId) {
      console.error('El UserId no está disponible.');
      alert('Hubo un problema al identificar al usuario.');
      return;
    }
  
    const requestBody = {
      descripcion: 'Cursos', // Solo buscamos el video con descripción "Cursos"
      userId: userId
    };
  
    this.productService.getVideos(requestBody).subscribe({
      next: (response) => {
        if (response) {
          const video = response[0]; // Toma el primer elemento del arreglo
          this.video = [video]; // Guarda el video en un arreglo (por consistencia)
        } else {
          console.error('No se encontró un video para "Cursos".');
        }
      },
      error: (err) => {
        console.error('Error al cargar el video:', err);
        alert('Hubo un problema al cargar el video. Inténtalo nuevamente.');
      }
    });
  }
  
  markVideoAsViewed(videoId: number): void {
    this.productService.changeStatusVideo(videoId).subscribe({
      next: () => {
        console.log(`El video con ID ${videoId} ha sido marcado como visto.`);
      },
      error: (err) => {
        console.error(`Error al cambiar el estado del video con ID ${videoId}:`, err);
        alert('Hubo un problema al actualizar el estado del video.');
      }
    });
  }
  
  startTour(): void {
    // Verifica si la lista de videos está vacía
    if (this.video.length === 0) {
      console.log('No hay videos disponibles para iniciar el tour.');
      return; // Detiene el inicio del tour
    }

    // Verifica si algún video ya ha sido visto
    const algunVideoVisto = this.video.some(video => video.OnboardingVideoUsers[0]?.Viewed);

    if (algunVideoVisto) {
      console.log('El tour no se iniciará porque algunos videos ya han sido vistos.');
      return; // Detiene el inicio del tour
    }

    const tour = {
      tourId: 'CursosTour',
      useOrb: false, // Desactiva el orb
      steps: this.getGeneralSteps(),
      completeCallback: () => {
        console.log('Tour completado');
        if (this.video.length > 0) {
          const video = this.video[0];
          this.markVideoAsViewed(video.OnboardingVideoUsers[0]?.id); // Marca como visto al completar
        }
        this.router.navigate(['/products/courses']); // Redirige al nuevo componente
      },
      skipCallback: () => {
        console.log('Tour omitido');
        if (this.video.length > 0) {
          const video = this.video[0];
          this.markVideoAsViewed(video.OnboardingVideoUsers[0]?.id); // Marca como visto al omitir
        }
        this.router.navigate(['/products/courses']); // Redirige al nuevo componente si se omite el tour
      }
    };
    document.querySelector('ngx-guided-tour')?.classList.add('cursos-tour');
    this.guidedTourService.startTour(tour); // Inicia el tour
  }
  
  getGeneralSteps(): TourStep[] {
    if (this.video.length > 0) {
      const video = this.video[0]; // Tomar el primer video cargado
      console.log('Videos:', video);
      return [
        {
          content: `
            <video controls width="100%" height="100%">
              <source src="${video.VideoUrl}" type="video/mp4">
              Tu navegador no soporta la reproducción de video.
            </video>
          `,
          title: 'Cursos',
          orientation: 'center', // Centrar el paso
          scrollAdjustment: 0 // Ajuste de desplazamiento
        }
      ];
    } else {
      return []; // Si no hay videos, no se muestra ningún paso
    }
  }    

  openAddCourseModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  confirmDelete(productId: any): void {
    Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            this.productService.deleteCourses(productId).subscribe({
                next: () => {
                    Swal.fire('Eliminado', 'El curso ha sido eliminado correctamente.', 'success');
                    this.loadData(); // Recargar la lista de cursos
                },
                error: (err) => {
                    Swal.fire('Error', 'No se pudo eliminar el curso. Inténtalo nuevamente.', 'error');
                    console.error(err);
                }
            });
        }
    });
  }
}
